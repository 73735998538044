import {AfterViewInit, Component, OnInit, Renderer2} from '@angular/core';
import {LangBusService} from "@shared/services";
import {EventData} from "@shared/services/langBus.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AuthState} from "@shared/stores/auth/auth.state";
import {Select, Store} from "@ngxs/store";
import {BehaviorSubject, Observable} from "rxjs";
import {ELang, IUser} from "@models/dist";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {Logout} from "@shared/stores/auth/auth.actions";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('submenu', [
      state(
        'hidden',
        style({
          height: '0px',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition(
        'visible => hidden',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hidden => visible',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
})
export class LayoutComponent implements OnInit {


  @Select(AuthState.getUser) user$?: Observable<IUser>
  @Select(AuthState.hasUnread) hasUnread$?: Observable<number>
  public langModal = false;
  public menuMode = 'static';
  public config = {
    dark: false,
    inputStyle: 'outlined',
    ripple: true,
    theme: 'lara-light-blue',
  };
  public menus = [
    {
      slug: 'admin',
      background: '#1A202C',
      label: 'menu.admin.title',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      baseLink: '/admin/inside',
      rotated: false,
      active: true,
      medias: false,
      links: [
        {
          label: 'menu.admin.dashboard',
          icon: 'home-outline',
          link: '/admin/inside/dashboard',
          display: false,
        },
        {
          label: 'menu.admin.users',
          icon: 'person-outline',
          link: '/admin/inside/users',
          display: false,
        },
        {
          label: 'menu.admin.transactions',
          icon: 'book-outline',
          link: '/admin/inside/transactions',
          display: false,
        },
        {
          label: 'menu.admin.repport',
          icon: 'document-text-outline',
          link: '/admin/inside/reports',
          display: false,
        },
        {
          label: 'menu.admin.compliance',
          icon: 'finger-print-outline',
          link: '/admin/inside/compliances',
          display: false,
        },
        // {
        //   label: 'menu.admin.passfort',
        //   icon: 'finger-print-outline',
        //   link: '/admin/inside/compliances/passfort',
        //   display: false,
        // },
        {
          label: 'menu.admin.passfort_v2',
          icon: 'finger-print-outline',
          link: '/admin/inside/compliances/passfort-v2',
          display: false,
        },
        {
          label: 'menu.admin.exits',
          icon: 'finger-print-outline',
          link: '/admin/inside/compliances/exits',
          display: false,
        },
        {
          label: 'menu.admin.withdrawals',
          icon: 'cash-outline',
          link: '/admin/inside/withdrawals',
          display: false,
        },
        {
          label: 'menu.admin.messaging',
          icon: 'mail-outline',
          link: '/admin/inside/messenger',
          display: false,
        },
        {
          label: 'menu.admin.roles',
          icon: 'person-outline',
          link: '/admin/inside/roles',
          display: false,
        }
      ]
    },
    {
      slug: 'ico',
      background: 'linear-gradient(45deg, #00FFFF, #D60580)',
      label: 'menu.ico.title',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      baseLink: '/admin/ico',
      rotated: false,
      active: false,
      medias: false,
      links: [
        {
          label: 'menu.admin.round',
          icon: 'cash-outline',
          link: '/admin/ico/rounds',
          display: false,
        },
        {
          label: 'menu.admin.sponsor',
          icon: 'people-outline',
          link: '/admin/ico/referrals',
          display: false,
        },
        {
          label: 'menu.admin.sponsor-game',
          icon: 'people-outline',
          link: '/admin/ico/referrals-game',
          display: false,
        },
        {
          label: 'menu.admin.roadmap',
          icon: 'map-outline',
          link: '/admin/ico/roadmap',
          display: false,
        }
      ]
    },
    {
      slug: 'research',
      background: 'linear-gradient(45deg, #6D34DB, #C79CCE)',
      label: 'menu.research.title',
      baseLink: '/admin/research',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      rotated: false,
      active: false,
      medias: false,
      links: [
        {
          label: 'menu.admin.dashboard',
          icon: 'home-outline',
          link: '/admin/research/dashboard',
          display: false,
        },
        {
          label: 'menu.admin.projects',
          icon: 'document-text-outline',
          link: '/admin/research/projects/waiting',
          display: false,
        },
        {
          label: 'menu.admin.validated',
          icon: 'document-text-outline',
          link: '/admin/research/projects/validated',
          display: false,
        },
        {
          label: 'menu.admin.submit',
          icon: 'document-text-outline',
          link: '/admin/research/projects/submit',
          display: false,
        },
        {
          label: 'menu.admin.ideas',
          icon: 'bulb-outline',
          link: '/admin/research/ideas',
          display: false,
        },
        {
          label: 'menu.admin.documents',
          icon: 'document-text-outline',
          link: '/admin/research/documents',
          display: false,
        },
        {
          label: 'menu.admin.promotions',
          icon: 'document-text-outline',
          link: '/admin/research/promotions',
          display: false,
        },
        {
          label: 'menu.admin.glossaires',
          icon: 'document-text-outline',
          link: '/admin/research/glossaires',
          display: true,
        },
        {
          label: 'menu.admin.stats',
          icon: 'document-text-outline',
          link: '/admin/research/stats',
          display: true,
        },
      ]
    },
    {
      slug: 'mining',
      background: 'linear-gradient(45deg, #C79CCE, #6D34DB)',
      label: 'menu.mining.title',
      baseLink: '/admin/mining',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      rotated: false,
      active: false,
      medias: false,
      links: [
        {
          label: 'menu.mining.dashboard',
          icon: 'home-outline',
          link: '/admin/mining/dashboard',
          display: false,
        },
        {
          label: 'menu.mining.parc',
          icon: 'home-outline',
          link: '/admin/mining/parc',
          display: false,
        },
        {
          label: 'menu.mining.monthly-order',
          icon: 'wallet-outline',
          link: '/admin/mining/monthly-order',
          display: false,
        },
        {
          label: 'menu.mining.smk',
          icon: 'wallet-outline',
          link: '/admin/mining/smk',
          display: false,
        },
        {
          label: 'menu.mining.mining-history',
          icon: 'time-outline',
          link: '/admin/mining/mining-history',
          display: false,
        },
        {
          label: 'menu.mining.daily-yield',
          icon: 'options-outline',
          link: '/admin/mining/daily-yield',
          display: false,
        },
        {
          label: 'menu.mining.monthly-repport',
          icon: 'file-tray-full-outline',
          link: '/admin/mining/monthly-repport',
          display: false,
        },
        {
          label: 'menu.mining.monthly-transactions',
          icon: 'receipt-outline',
          link: '/admin/mining/monthly-transactions',
          display: false,
        },
        {
          label: 'menu.mining.marketplace',
          icon: 'storefront-outline',
          link: '/admin/mining/marketplace',
          display: false,
        },
        {
          label: 'menu.mining.transactions',
          icon: 'receipt-outline',
          link: '/admin/mining/sepa',
          display: false,
        },

      ]
    },
    {
      slug: 'gravity',
      background: 'linear-gradient(45deg, #C79CCE, #6D34DB)',
      label: 'menu.gravity.title',
      baseLink: '/admin/gravity',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      rotated: false,
      active: false,
      medias: false,
      links: [
        {
          label: 'menu.gravity.sgk',
          icon: 'home-outline',
          link: '/admin/gravity/sgk',
          display: false,
        },
        {
          label: 'menu.gravity.transactions',
          icon: 'receipt-outline',
          link: '/admin/gravity/transactions',
          display: false,
        },
        {
          label: 'menu.gravity.kpi',
          icon: 'options-outline',
          link: '/admin/gravity/kpi',
          display: false,
        },

      ]
    },
    {
      slug: 'markets',
      background: 'linear-gradient(45deg, #C79CCE, #6D34DB)',
      label: 'menu.markets.title',
      baseLink: '/admin/markets',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      rotated: false,
      active: false,
      medias: false,
      links: [
        {
          label: 'menu.markets.orders',
          icon: 'book-outline',
          link: '/admin/markets/orders',
          display: false,
        },
      ]
    },
    {
      slug: 'media',
      background: 'linear-gradient(45deg, #6D34DB, #C79CCE)',
      label: 'menu.media.title',
      baseLink: '/admin/medias',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      rotated: false,
      active: false,
      medias: true,
      links: [
        {
          label: 'menu.media.all',
          icon: 'home-outline',
          link: '/admin/medias/dashboard',
          display: false,
        },
        {
          label: 'menu.media.article',
          icon: 'document-text-outline',
          link: '/admin/medias/articles',
          display: false,
        },
        {
          label: 'menu.media.lives',
          icon: 'document-text-outline',
          link: '/admin/medias/lives',
          display: false,
        },
        {
          label: 'menu.media.videos',
          icon: 'document-text-outline',
          link: '/admin/medias/videos',
          display: false,
        },
        {
          label: 'menu.media.podcast',
          icon: 'document-text-outline',
          link: '/admin/medias/podcasts',
          display: false,
        },
      ]
    }
  ]
  public menuInactiveDesktop = false;
  public menuActiveMobile = false;
  public overlayMenuActive = false;
  public staticMenuInactive = false;
  public profileActive = true;
  public topMenuActive = true;
  public topMenuLeaving = true;
  public theme = "lara-light-blue";
  public documentClickListener: any = null;
  public documentSwipeListener = null;
  public documentSwipeMoveListener = null;
  public menuClick = false;
  public topMenuButtonClick = false;
  public configActive = false;
  public configClick = false;
  public xDown = null;
  public yDown = null;
  public ref?: any;
  public darkMode = false;
  public selectedKeys = 'admin';

  languages: any[] = [
    {name: 'Français', key: ELang.FR, flag: "/assets/flag/fr.svg"},
    {name: 'English', key: ELang.EN, flag: "/assets/flag/en.svg"}];

  public selectedMenu = this.menus.find((menu: any) => menu.slug === this.selectedKeys);
  ELang = ELang;
  langSelected: ELang = ELang.EN;
  modalLang = false;

  constructor(
    private langBusService: LangBusService,
    public translate: TranslateService,
    private router: Router,
    private store: Store,
    public renderer: Renderer2) {
    this.openDirectoryByRoute()
  }

  ngOnInit() {
    window.document.body.classList.add('dashboard')
    this.langSelected = this.translate.currentLang as ELang;

    this.langBusService.on('open', (data: boolean) => {
      this.langSelected = this.translate.currentLang as ELang;

      this.langModal = true;
    })
    this.langBusService.on('close', (data: boolean) => {
      this.langSelected = this.translate.currentLang as ELang;

      this.langModal = false;
    })
  }

  openDirectoryByRoute() {
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const urlDirectory = `/${urlArray[1]}/${urlArray[2]}`;
    if (urlDirectory) {
      this.menus.forEach(menu => {
        if (menu.baseLink.includes(urlDirectory)) {
          this.selectMenu(menu, true)
        }
      })
      // this.directory.forEach((element) => {
      //   if (element.baseLink === urlDirectory) {
      //     this.openDirectory(element);
      //   }
      // });
    }
  }

  selectMenu(menu: any, route = false) {
    this.selectedKeys = menu.slug;
    this.selectedMenu = this.menus.find((menu) => menu.slug === this.selectedKeys);
    this.menus.forEach(menu => {
      menu.active = false;
      // menu.rotated = false;
      if (menu.slug === this.selectedKeys) {
        menu.active = true;
        // menu.rotated = true;
        if (!route) this.navigateTo(menu.links[0])
      }
    })
  }


  toggleLanguageModal() {
    this.langSelected = this.translate.currentLang as ELang;
    this.modalLang = !this.modalLang
  }

  navigateTo(item: any) {
    console.log("item =>", item.link)
    this.router.navigate([item.link]);
  }

  public openLanguageModal() {
    this.langModal = !this.langModal;
    this.langBusService.emit(new EventData('open', this.langModal));
  }

  closeLangModal() {
    this.langBusService.emit(new EventData('close', this.langModal));
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  goToMessenger() {
    this.router.navigateByUrl('/admin/inside/messenger')
  }

  ngAfterViewInit() {
    //document.addEventListener('touchstart', this.handleTouchStart, false);
    //document.addEventListener('touchmove', this.handleTouchMove, false);
    this.documentClickListener = this.renderer.listen(
      'body',
      'click',
      (event) => {
        if (event.clientX < 300) {
          return;
        }
        this.menuActiveMobile = false;
        if (!this.isDesktop()) {
          if (!this.menuClick) {
            this.menuActiveMobile = false;
          }

          if (!this.topMenuButtonClick) {
            this.hideTopMenu();
          }
        } else {
          if (!this.menuClick && this.isOverlay()) {
            this.menuInactiveDesktop = true;
          }
          if (!this.menuClick) {
            this.overlayMenuActive = false;
          }
        }

        if (this.configActive && !this.configClick) {
          this.configActive = false;
        }

        this.configClick = false;
        this.menuClick = false;
        this.topMenuButtonClick = false;
      }
    );
  }

  hideTopMenu() {
    this.topMenuLeaving = true;
    setTimeout(() => {
      this.topMenuActive = false;
      this.topMenuLeaving = false;
    }, 1);
  }

  onMenuClick() {
    this.menuClick = true;
  }

  isStatic() {
    return this.menuMode === 'static';
  }

  isOverlay() {
    return this.menuMode === 'overlay';
  }

  isDesktop() {
    return window.innerWidth > 992;
  }

  onSearchClick() {
    this.topMenuButtonClick = true;
  }

  toggleMenu() {
    this.menuClick = true;
    if (this.isDesktop()) {
      if (this.menuMode === 'overlay') {
        if (this.menuActiveMobile === true) {
          this.overlayMenuActive = true;
        }
        this.overlayMenuActive = !this.overlayMenuActive;
        this.menuActiveMobile = false;
      } else if (this.menuMode === 'static') {
        this.staticMenuInactive = !this.staticMenuInactive;
      }
    } else {
      this.menuActiveMobile = !this.menuActiveMobile;
      this.topMenuActive = false;
    }

    // event.preventDefault();
  }

}
