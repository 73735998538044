import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsModule} from '@ngxs/store';
import {AppRoutingModule} from './app-routing.module';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule, USE_EMULATOR as FIRESTORE_EMULATOR,} from '@angular/fire/compat/firestore';
import {environment} from '@environments/environment';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR,} from '@angular/fire/compat/auth';
import {initializeApp} from 'firebase/app';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule,} from '@angular/common/http';
import {USE_EMULATOR as FUNCTIONS_EMULATOR} from '@angular/fire/compat/functions';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ReactiveLoaderService} from "@app/shared/services";
import {AuthState} from "@shared/stores/auth/auth.state";
import {globalHttpInterceptor} from "@shared/helpers/http.interceptor";
import {AppComponent} from "@app/app.component";
import {SharedModule} from "@shared/shared.module";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {SidebarModule} from './shared/components/sidebar/sidebar.module';
import {SocketIoModule, SocketIoConfig} from 'ngx-socket-io';
import {GravityState} from "@gravity/shared/store/gravity.state";
import {ResearchState} from "@app/research/shared/stores/research.state";
import {MiningState} from "@mining/shared/store/mining.state";

export const firebaseApp = initializeApp(environment.firebase);
const config: SocketIoConfig = {url: environment.websocketApi, options: {}};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    SidebarModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    SharedModule,
    ConfirmDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxsModule.forRoot([
      AuthState, GravityState, ResearchState,MiningState
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ToastModule,
    SocketIoModule.forRoot(config),

  ],
  providers: [
    MessageService,
    {
      provide: AUTH_EMULATOR,
      useValue: !environment.emulator
        ? undefined
        : ['http://localhost:9099', 9099],
    },
    {
      provide: FIRESTORE_EMULATOR,
      useValue: !environment.emulator
        ? undefined
        : ['http://localhost:8080', 8080],
    },
    {
      provide: FUNCTIONS_EMULATOR,
      useValue: !environment.emulator
        ? undefined
        : ['http://localhost:5001', 5001],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: globalHttpInterceptor,
      multi: true,
    },
    ReactiveLoaderService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
