<div class="twoFaActivate">
  <button
    (click)="close()"
    class="close"
    icon="pi pi-times"
    pButton
  ></button>
  <div class="modal-header">
    <div [innerHtml]="title | translate" class="title"></div>
    <div *ngIf="subtitle" [innerHtml]="subtitle | translate" class="subline"></div>
  </div>

  <ng-container *ngIf="type !== ETwoFaType.ACTIVATE || step === 2">
    <div *ngIf="displayHotp" class="code-body mt-4">
      <h6 [innerHtml]="'2FA.summitCode' | translate" class="code-title"></h6>
      <code-input
        (codeChanged)="onHotpChanged($event)"
        (codeCompleted)="onCodeComplete()"
        [codeLength]="codeLength"
        [code]="hotp"
        [isCodeHidden]="false">
      </code-input>
    </div>
    <div *ngIf="displayTotp" class="code-body mt-3">
      <h6 [innerHtml]="'2FA.googleCode' | translate" class="code-title"></h6>
      <code-input
        (codeChanged)="onTotpChanged($event)"
        (codeCompleted)="onCodeComplete()"
        [codeLength]="codeLength"
        [code]="totp"
        [initialFocusField]="0"
        [isCodeHidden]="false">
      </code-input>
    </div>
    <div
      [innerHtml]="'2FA.codeAvailability' | translate"
      class="mt-2 text-red-700"
    ></div>
  </ng-container>
  <div class="modal-body">
    <div class="col 12 flex actions-buttons">
      <div class="col">
        <button
          (click)="submit()"
          [disabled]="!enableSubmit()"
          [label]="step === 2 || type !== ETwoFaType.ACTIVATE
              ? (submitButton | translate)
              : ('Common.next' | translate)"
          bgColor="gradient-button"
          pButton
          pRipple
          rButton
          rId="mfa-totp-button"
        ></button>
      </div>
    </div>
  </div>
</div>
