import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { switchMap, take, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ReactiveLoaderService, ToastService, TokenService } from "@app/shared/services";
import { reactiveButtonState } from "@app/shared/services/reactive-loader.service";
import { Store } from "@ngxs/store";
import { Logout } from "@shared/stores/auth/auth.actions";
import { STORAGE_DEVICE_UID } from "@app/app.declaration";
import { EToastSeverity } from "@models/dist";

export const reactivityPost = [
  {
    rId: "mfa-totp-button",
    route: "/mfa-totp/signin"
  },
  {
    rId: "mfa-totp-button",
    route: "/mfa-totp"
  },
  {
    rId: "mfa-totp-button",
    route: "/mfa-totp/hotp-revoke"
  },
  {
    rId: "mfa-totp-button",
    route: "/mfa-totp/hotp-create"
  },
  {
    rId: "mfa-totp-button",
    route: "/change-password"
  },
  {
    rId: "summit-register",
    route: "/users"
  },
  {
    rId: "summit-verify-email",
    route: "/verify-email"
  },
  {
    rId: "summit-email-verified",
    route: "/email-verified"
  },
  {
    rId: "summit-reset-password",
    route: "/reset-password"
  },


]

@Injectable()
export class globalHttpInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private tokenService: TokenService,
    private auth: AngularFireAuth,
    private store: Store,
    private reactiveLoader: ReactiveLoaderService
  ) {
  }

  getUniqueId() {
    return (
      uuidv4()
    );
  }

  searchReactivity(route: string) {
    return reactivityPost.find(r => route.endsWith(r.route));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.idToken.pipe(
      take(1),
      switchMap((token: any) => {
        const isApi = request.url.includes(environment.apiUrl) || request.url.includes(environment.nestApiUrl);

        if (token && isApi) {
          let summitId = localStorage.getItem(STORAGE_DEVICE_UID);
          if (!summitId) {
            summitId = this.getUniqueId();
            localStorage.setItem(STORAGE_DEVICE_UID, summitId);
          }
          if (request.method === 'POST' && this.searchReactivity(request.url)) {
            this.reactiveLoader.setReactiveButtonState(this.searchReactivity(request.url)?.rId as string, reactiveButtonState.loading);
          }
          request = request.clone({ setHeaders: { Authorization: `Bearer ${token}`, "X-SUMMIT-DEVICE-UID": summitId } });
        }
        return next.handle(request).pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.status === 200 && isApi && token) {
              if (request.method === 'POST' && this.searchReactivity(request.url)) {
                this.reactiveLoader.setReactiveButtonState(this.searchReactivity(request.url)?.rId as string, reactiveButtonState.success);
              }
            }
          }),
          catchError(error => {
            if ((error.status === 401 || error.status === 403) && isApi) {
              if (error.message && error.message === 'Reauthentication required') {
                this.toastService.addToast(
                  EToastSeverity.info,
                  'info',
                  'autoDisconectTitle'
                );
              }
              this.store.dispatch(new Logout());
              //Unautorized
            } else if (error.status === 400 && isApi && token) {
              if (request.method === 'POST' && this.searchReactivity(request.url)) {
                this.reactiveLoader.setReactiveButtonState(this.searchReactivity(request.url)?.rId as string, reactiveButtonState.error);
              }
            } else if (isApi && token) {
              if (request.method === 'POST' && this.searchReactivity(request.url)) {
                this.reactiveLoader.setReactiveButtonState(this.searchReactivity(request.url)?.rId as string, reactiveButtonState.error);
              }
            }
            // return throwError(() => new Error(error));

            // return error as any as HttpEvent<any>;

            throw new HttpErrorResponse(error);
          })
        );
      }));
  }
}
