import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firstValueFrom, switchMap, take } from "rxjs";
import { environment } from "@environments/environment";
import { IChangePassword } from '@models/dist';
import { AngularFireAuth } from '@angular/fire/compat/auth';

const BASENEST = `${environment.nestApiUrl}/api/sgp/users`;

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private auth: AngularFireAuth
  ) {
  }


  async resetPassword(email: string): Promise<any> {
    const res = this.http.post(`${BASENEST}/reset-password`, { email });
    return firstValueFrom(res);
  }


  login(email: string, password: string) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
  }

  async changePassword(data: IChangePassword): Promise<any> {
    const res = this.http.post(`${BASENEST}/change-password`,
      data);
    return firstValueFrom(res);
  }

  async getAdminBearerToken() {
    return firstValueFrom(this.auth.idToken);
  }
}
