import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from "@ngxs/store";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {Logout} from "@shared/stores/auth/auth.actions";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  // @Select(AuthState.getRoles) roles$: Observable<string[]> | undefined;
  constructor(private afAuth: AngularFireAuth,
              private router: Router, private store: Store) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const auth = getAuth()
    return new Promise(resolve => {
      const unsubscribe = onAuthStateChanged(auth, async (user: any) => {
        if (!!user) {

          const parseToken = await user.getIdTokenResult(true)
          if (parseToken?.claims?.r && !!parseToken?.claims?.r['sgp']) {
            if ((parseToken?.claims?.r['sgp'] as string[])?.includes('a')) {
              resolve(true)
            } else {
              this.store.dispatch(new Logout())
              resolve(false)
            }
          }
        } else {
          this.store.dispatch(new Logout())
          resolve(false)
        }
        unsubscribe();
      });
    });
  }
}
